html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  all: unset;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  font-family: 'Maison Neue', sans-serif;
}